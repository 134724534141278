<template>
  <div class="flex">
    <vx-card title="Team bearbeiten"class="mr-4 lg:w-1/2">
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full mb-1">
          <div class="vx-row mb-1">
            <div class="vx-col  w-full mb-4">
              <vs-input class="w-full" v-validate="'required|min:3'"  label-placeholder="Name" name="teamName" v-model="data.name"/>
              <span class="text-danger text-sm">{{ errors.first('teamName') }}</span>
            </div>
          </div>
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-1">
          <div class="vx-row mb-2">
            <div class="vx-col w-1/2 mb-0">

            </div>
          </div>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button class="mr-3 mb-2" :disabled="!validateForm" @click="updateTeam">Speichern</vs-button>
          <vs-button color="warning" type="border" class="mb-2" to="/teams">Abbrechen</vs-button>
        </div>
      </div>
    </vx-card>
    <vx-card title="Team Mitglieder" class="ml-4 lg:w-1/2">
      <div class="vx-col sm:w-1/2 w-full mb-1">
        <div class="vx-row mb-2">
          <div class="vx-col w-full mt-2">
            <vs-select
                class="selectExample w-full mb-5"
                label="Mitglieder Auswahl"
                v-model="selectedUser"
                autocomplete
                @change="addUserToTeam(selectedUser)"
            >
              <vs-select-item
                  :key="index"
                  :value="item"
                  :text="`${item.detail.first_name} ${item.detail.last_name}`"
                  v-for="(item,index) in users"/>
            </vs-select>
            <vs-chip
                color="primary"
                @click="removeUserFromTeam(chip)"
                :key="index"
                v-for="(chip, index) in teamUsers"
                closable>
             {{`${chip.detail.first_name} ${chip.detail.last_name}`}}
            </vs-chip>
            <vs-chip class="font-bold" color="primary"v-if="teamUsers.length <= 0" >Für das Team gibt's keine Mitglieder</vs-chip>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import ApiService from "../../../api";

export default {
  name: "Team",
  data() {
    return {
      data:[],
      users: [],
      selectedUser: [],
      teamUsers: [],
      teamId: this.$route.params.id
    }
  },
  created() {
    this.fetchUsers()
    this.fetchTeam()
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.data.name !== ''
    },
  },

  methods: {
    addUserToTeam(userTeam) {
      if(userTeam.detail.first_name !== 'SYSTEM') {
        this.$vs.loading()
        ApiService.post('teams/' + this.$route.params.id +'/add-user/' + userTeam.id).then((response) => {
          this.$vs.loading.close()
          if(response.data.status === 'success') {
            this.fetchTeam()
            return this.$vs.notify({
              title: 'Mitarbeiter zum Team hinzugefügt',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            });
          }

        }).catch((error) => {
          this.$vs.loading.close()
          let errorMessage = error.message;
          if (error.response.data.message) {
            errorMessage = error.response.data.message;
          }
          this.$vs.notify({
            title: 'Error',
            text: errorMessage,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
      }
    },
    removeUserFromTeam(userTeam) {
        this.$vs.loading()
        ApiService.post('teams/' + this.$route.params.id + '/remove-user/' + userTeam.id).then((response) => {
          this.$vs.loading.close()
          if (response.data.status === 'success') {
            this.fetchTeam()
            return this.$vs.notify({
              title: 'Mitarbeiter erfolgreich entfernt',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            });
          }

        }).catch((error) => {
          this.$vs.loading.close()
          let errorMessage = error.message;
          if (error.response.data.message) {
            errorMessage = error.response.data.message;
          }
          this.$vs.notify({
            title: 'Error',
            text: errorMessage,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },
    fetchUsers() {
      ApiService.get('users').then(response => {
        if (response.status !== 200) {
          return;
        }

        this.users = response.data.result;
        this.selectedUser = response.data.result[0]

      }).catch(response => {

      })
    },
    fetchTeam() {
      ApiService.get('teams/' + this.teamId).then(response => {
        if (response.status !== 200) {
          return;
        }
        this.teamUsers = response.data.result.users;
        this.data = response.data.result
      }).catch(response => {

      })
    },
    updateTeam() {
      if (!this.validateForm) return

      this.$vs.loading()

      ApiService.put('teams/' + this.$route.params.id, this.data).then((response) => {

        this.$vs.loading.close()

        if(response.data.status === 'success') {
          return this.$vs.notify({
            title: 'Das Team wurde erfolgreich bearbeitet ',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }

      }).catch((error) => {
        let errorMessage = error.message;

        if (error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        this.$vs.notify({
          title: 'Error',
          text: errorMessage,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
  }
}
</script>

<style scoped>

</style>